/*=== MEDIA QUERY ===*/
@import "https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Roboto:300,400,500,700";

/* Controls
---------------------------------------------------------------------- */
/* Container
---------------------------------------------------------------------- */

html {
  font-size: 16px;
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
}

h1 {
  font-family: "Montserrat", sans-serif;
  color: #222222;
  font-weight: 600;
  font-size: 2.8rem;
}

h2 {
  font-family: "Montserrat", sans-serif;
  color: #222222;
  font-weight: 600;
  font-size: 2.5rem;
}

h3 {
  font-family: "Montserrat", sans-serif;
  color: #222222;
  font-weight: 600;
  font-size: 2.25rem;
}

h4 {
  font-family: "Montserrat", sans-serif;
  color: #222222;
  font-weight: 600;
  font-size: 1.6875rem;
}

h5 {
  font-family: "Montserrat", sans-serif;
  color: #222222;
  font-weight: 600;
  font-size: 1.375rem;
}

h6 {
  font-family: "Montserrat", sans-serif;
  color: #222222;
  font-weight: 600;
  font-size: 1.25rem;
}

p {
  font-size: 1rem;
  line-height: 26px;
  color: #848484;
  margin-bottom: 0;
}

a {
  font-size: 1rem;
  line-height: 26px;
  color: #848484;
  margin-bottom: 0;
  transition: 0.2s ease;

  &:focus, &:hover {
    color: #ff6600;
    text-decoration: none;
  }
}

li, blockquote, label {
  font-size: 1rem;
  line-height: 26px;
  color: #848484;
  margin-bottom: 0;
}

.form-control {
  background: transparent;
  border: 1px solid #b3b3b3;
  border-radius: 0;
  font-size: 0.9375rem;
  padding: 15px 20px;
  height: 48px;
  margin-bottom: 20px;
  color: #b3b3b3;

  &::-webkit-input-placeholder {
    color: #b3b3b3;
    font-size: 0.9375rem;
  }

  &:focus {
    border: 1px solid #ff6600;
    box-shadow: none;
  }
}

ul.social-links {
  margin-bottom: 0;

  li {
    &:first-child a {
      padding-left: 0;
    }

    a {
      padding: 0 15px;
      display: block;

      i {
        font-size: 20px;
        color: #222222;
      }
    }
  }
}

.shadow {
  box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
}

.testimonial {
  .testimonial-item {
    margin-bottom: 30px;

    .comment {
      padding: 40px 30px;
      border: 1px solid #e5e5e5;
      margin-bottom: 20px;
      position: relative;
      transition: 0.3s ease;

      &:hover {
        box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
        background: #ff6600;
        border: 1px solid #ff6600;

        p {
          color: #fff;
        }

        &:before, &:after {
          border-color: transparent #ff6600 transparent transparent;
        }
      }

      &:before {
        content: "";
        position: absolute;
        left: -2px;
        bottom: -27px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 30px 27px 0;
        border-color: transparent #e5e5e5 transparent transparent;
        transition: 0.3s ease;
      }

      &:after {
        content: "";
        position: absolute;
        left: -1px;
        bottom: -26px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 28px 27px 0;
        border-color: transparent #fff transparent transparent;
        transition: 0.3s ease;
      }
    }

    .media {
      margin-left: 50px;

      img {
        height: 65px;
        width: 65px;
        border-radius: 100%;
      }

      .media-body {
        margin-left: 15px;
        align-self: center;

        .name p {
          font-family: "Montserrat", sans-serif;
          color: #222222;
        }

        .profession p {
          font-size: 0.8126rem;
          color: #ff6600;
        }
      }
    }
  }

  .testimonial-slider {
    .item {
      padding-bottom: 10px;

      .block {
        padding: 40px;
        text-align: center;
        margin: 10px;
        border-radius: 5px;

        .image {
          margin-top: 30px;
          margin-bottom: 5px;
          width: 100%;
          display: flex;
          justify-content: center;

          img {
            height: 40px;
            width: 40px;
            border-radius: 100%;
          }
        }

        p {
          font-family: "Montserrat", sans-serif;
          font-style: italic;
          color: #888888;
        }

        cite {
          font-style: normal;
          font-size: 14px;
          color: #161616;
        }
      }
    }

    .owl-dots .owl-dot {
      &:hover span, &.active span {
        background: #ff6600;
      }
    }
  }
}

.news-hr .news-post-hr {
  display: flex;

  &:hover {
    box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
  }

  .post-thumb {
    flex-basis: 50%;

    img {
      width: 100%;
    }
  }

  .post-contents {
    flex-basis: 50%;
    padding: 30px;
    position: relative;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: #e5e5e5;

    .post-title h6 {
      margin-bottom: 36px;
      margin-top: 5px;

      a {
        text-transform: uppercase;
        font-weight: 400;
        position: relative;
        font-size: 20px;
        color: #222222;

        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          top: 37px;
          content: "";
          background: #ff6600;
          width: 30px;
          height: 2px;
        }
      }
    }

    .post-exerpts p {
      font-size: 15px;
    }

    .date {
      margin-top: 17px;
      width: 65px;
      height: 65px;
      text-align: center;
      background: #ff6600;
      display: flex;
      align-items: center;
      justify-content: center;

      h4 {
        color: #fff;
        font-weight: 400;

        span {
          display: block;
          font-size: 1rem;
          font-weight: 400;
        }
      }
    }

    .more {
      text-align: right;
      margin-top: -25px;

      a {
        font-family: "Montserrat", sans-serif;
        color: #222222;
        text-transform: uppercase;
        font-size: 14px;
      }
    }

    &:after {
      content: "";
      position: absolute;
      right: 100%;
      bottom: 0;
      top: 50%;
      transform: translate(0, -50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 10px 0;
      border-color: transparent #fff transparent transparent;
    }
  }
}

ul.pagination {
  margin-top: 30px;
  margin-bottom: 30px;

  .active a {
    box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
    background-color: #ff6600 !important;
    color: #fff !important;
    border-color: #ff6600 !important;
  }

  li {
    margin-right: 10px;

    a {
      font-size: 16px;
      padding: 0;
      height: 50px;
      width: 50px;
      line-height: 50px;
      text-align: center;
      border-color: transparent;
      color: #848484;
      border: 1px solid #e5e5e5;
      border-radius: 0 !important;

      &:hover, &:focus {
        box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
        background-color: #ff6600 !important;
        color: #fff !important;
        border-color: #ff6600 !important;
      }
    }
  }
}

.sponsors {
  position: relative;

  .block {
    margin-bottom: 40px;

    .image-block {
      padding: 45px 0;
      background: #fff;
      width: 244px;
      cursor: pointer;
      transition: all 0.3s ease;
      border: 1px solid transparent;
      margin-bottom: 10px;

      &:hover {
        box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
        border: 1px solid #ff6600;
      }

      img {
        height: 55px;
      }
    }

    .list-inline-item:not(:last-child) {
      margin-right: 15px;
    }
  }

  .sponsor-title {
    margin-top: 10px;
    margin-bottom: 30px;

    h5 {
      color: #ff6600;
    }
  }

  .sponsor-btn {
    margin-top: 10px;
    margin-bottom: 30px;
  }
}

.schedule.two {
  .schedule-contents {
    box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0;

    .schedule-item {
      ul {
        margin-bottom: 0;

        li {
          list-style: none;

          div {
            display: inline-block;
          }

          .time {
            width: 20%;
          }

          .speaker, .subject {
            width: 30%;
          }

          .venue {
            width: 18.6%;
          }
        }
      }

      li {
        &.headings {
          padding: 25px 0;
          background: #ff6600;

          div {
            color: #fff;
            text-transform: uppercase;
            font-family: "Montserrat", sans-serif;
          }
        }

        &.schedule-details {
          &:not(:last-child) {
            border-bottom: 1px solid #e5e5e5;
          }

          .block {
            text-align: center;
            padding: 0;
            background: #fff;
            width: 100%;
            transition: 0.2s ease-in;

            div {
              padding-top: 25px;
              padding-bottom: 25px;
              color: #222222;
              font-family: "Montserrat", sans-serif;

              i {
                font-size: 1.1875em;
                color: #c7c8c9;
              }

              img {
                border-radius: 100%;
              }

              span {
                &.time {
                  margin-left: 0px;
                }

                &.name {
                  margin-left: 0px;
                  transition: 0.2s ease-in;
                }
              }

              &:not(:last-child) {
                border-right: 1px solid #e5e5e5;
              }
            }

            &:not(:last-child) {
              border-bottom: 1px solid #e5e5e5;
            }

            &:hover {
              box-shadow: none;
              transform: scale(1);

              span.name {
                color: #ff6600;
              }
            }
          }
        }
      }
    }
  }

  .schedule-tab {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    ul li.nav-item {
      margin-right: 0;
      margin-bottom: 10px;

      a {
        font-family: "Montserrat", sans-serif;
        text-transform: uppercase;
        font-size: 20px;
        color: #222222;
        font-weight: 500;
        display: block;
        border-radius: 0;
        border: 1px solid #e5e5e5;
        padding: 20px 30px;

        span {
          margin-top: 2px;
          font-family: "Roboto", sans-serif;
          display: block;
          font-size: 14px;
          color: #848484;
          font-weight: 400;
        }

        &.active {
          background: #ff6600;
          color: #fff;
          position: relative;

          span {
            color: #fff;
          }

          &:after {
            content: "";
            position: absolute;
            left: 100%;
            right: 0;
            bottom: 0;
            top: 50%;
            transform: translate(0, -50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 0 10px 10px;
            border-color: transparent transparent transparent #ff6600;
          }
        }
      }
    }
  }
}

.speakers.white .speaker-item {
  background: #fff;
  border: 1px solid #e5e5e5;

  &:hover {
    box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
  }
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.overlay {
  &:before {
    content: "";
    background: url(~/images/background/objects.png) no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:after {
    content: "";
    background: -webkit-linear-gradient(-45deg, #1e5799 0%, #1d1546 0%, #1a0b25 100%, #207cca 100%);
    opacity: 0.25;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.overlay-lighter:before {
  content: "";
  background: -webkit-linear-gradient(-45deg, #1e5799 0%, #1d1546 0%, #1a0b25 100%, #207cca 100%);
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.text-overlay:after {
  content: "CONFERENCE";
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 215px;
  width: 100%;
  height: 300px;
  background-position: center center;
  position: absolute;
  top: 40%;
  left: 50%;
  opacity: 0.05;
  transform: translateX(-50%);
}

.overlay-primary:before {
  content: "";
  background: rgba(255, 102, 0, 0.93);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay-dark:before {
  content: "";
  background: -webkit-linear-gradient(-45deg, #1e5799 0%, #1d1546 0%, #1a0b25 100%, #207cca 100%);
  opacity: 0.75;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay-white:before {
  content: "";
  background: linear-gradient(0deg, rgba(245, 245, 248, 0.8) 0%, #f7f7f7 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay-white-slant:before {
  content: "";
  width: 100%;
  margin-left: -45%;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: skewX(-20deg);
}

span.alternate {
  color: #ff6600;
  font-style: normal;
  font-weight: 400;
}

button:hover {
  cursor: pointer;
}

.bg-gray {
  background: #fafafa;
}

.bg-blue {
  background: #ff6600;
}

.bg-banner-one {
  background: url(~/images/background/homepage-one-banner.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}


.bg-about {
  background: url(~/images/background/pattern-background.jpg) no-repeat;
  background-size: cover;
}

.bg-speaker {
  background-size: cover;
  background-position: center center;
}

.bg-timer {

  background-size: cover;
  background-position: center center;
}

.bg-schedule {

  background-size: cover;
}

.bg-service {
  background: url(~/images/background/feature-background.jpg) no-repeat;
  background-size: cover;
}

.bg-registration {
  background: url(~/images/background/registration-background.jpg) no-repeat;
  background-size: cover;
}

.bg-registration-two {

  background-size: cover;
}

.bg-ticket {

  background-size: cover;
  background-position: center center;
}

.bg-sponsors {
  background: url(~/images/background/sponsors-bg.jpg) fixed no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-subscribe {
  background: url(~/images/background/newsletter-bg.jpg) fixed no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-title {
  background: url(~/images/background/page-title-bg.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.section {
  padding: 90px 0;
}

.border-less {
  border-bottom: 1px solid #e5e5e5;
}

.section-title {
  margin-top: 25px;
  text-align: center;
  margin-bottom: 40px;

  h3 {
    margin-bottom: 20px;
  }

  &.white {
    h3, p {
      color: #fff;
    }
  }
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.page-title {
  text-align: center;
  position: relative;
  padding: 165px 0 65px;

  h3, li, a {
    color: #fff;
  }

  .title h3 {
    font-weight: 500;
    text-transform: uppercase;
  }

  .breadcrumb {
    background: transparent;

    .breadcrumb-item {
      float: none;
      display: inline-block;
      text-transform: uppercase;

      + .breadcrumb-item::before {
        content: "\f105";
        font-family: FontAwesome;
        color: #fff;
      }

      &.active {
        color: #fff;
      }
    }
  }
}

.video {
  position: relative;

  &:before {
    border-radius: 3px;
  }

  img {
    width: 100%;
    border-radius: 8px;
  }

  .video-button {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .video-box a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      height: 125px;
      width: 125px;
      font-size: 40px;
      background: #ff6600;
      border-radius: 100%;
      color: #fff;
      line-height: 125px;
      text-align: center;
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.form-control.white {
  background: #fff;
  color: #222222;
}

select.form-control:not([size]):not([multiple]) {
  height: 100%;
}

.left {
  overflow: hidden;

  img {
    margin-left: -40px;
  }
}

.right {
  overflow: hidden;

  img {
    margin-left: 40px;
  }
}

.hide-overflow {
  overflow: hidden;
}

.mfp-with-zoom {
  .mfp-container, &.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
  }

  &.mfp-ready {
    .mfp-container {
      opacity: 1;
    }

    &.mfp-bg {
      opacity: 0.8;
    }
  }

  &.mfp-removing {
    .mfp-container, &.mfp-bg {
      opacity: 0;
    }
  }
}

.border-top {
  border-top: 1px solid;
}

.slick-slide:focus {
  outline: 0;
}

.btn {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  transition: all 0.3s ease;
}

.btn-main {
  padding: 25px 45px;
  border-radius: 3px;
  background: #ff6600;
  color: #fff;
  outline: none;

  &:hover {
    color: #fff;
  }

  &:focus {
    color: #fff;
    box-shadow: none;
  }
}

.btn-main-md {
  padding: 15px 40px;
  border-radius: 0;
  background: #ff6600;
  color: #fff;
  outline: none;
  font-size: 0.9375rem;

  &:hover {
    color: #fff;
    background: #e65c00;
  }

  &:focus {
    color: #fff;
    box-shadow: none;
  }
}

.btn-transparent-md {
  padding: 15px 40px;
  background: transparent;
  outline: none;
  font-size: 0.9375rem;
  color: #222222;
  border: 1px solid #e5e5e5;
  border-radius: 0;

  &:hover {
    background: #ff6600;
    border-color: #ff6600;
    color: #fff;
  }
}

.btn-main-sm {
  padding: 15px 35px;
  border-radius: 3px;
  background: #ff6600;
  color: #fff;
  outline: none;
  font-size: 14px;

  &:hover {
    color: #fff;
  }

  &:focus {
    color: #fff;
    box-shadow: none;
  }
}

.btn-white-md {
  background: #fff;
  color: #222222;
  padding: 15px 40px;
  border-radius: 0;

  &:hover {
    background: #ff6600;
    color: #fff;
  }
}

.btn-rounded-icon {
  border-radius: 100px;
  color: #fff;
  border: 1px solid #fff;
  padding: 13px 50px;
}

.main-nav {
  background: #fff;

  .navbar-brand {
    padding: 14px 40px 14px 50px;
    border-right: 1px solid #e5e5e5;
  }

  .navbar-toggler {
    font-size: 1.5rem;
    margin-right: 15px;
  }

  .navbar-nav .nav-item {
    position: relative;
    font-family: "Roboto", sans-serif;

    .nav-link {
      position: relative;
      text-align: center;
      color: #222222;
      padding-left: 5px;
      padding-right: 17px;
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
      font-size: 1.06rem;
      font-family: "Montserrat", sans-serif;

      span {
        color: #222222;
        margin-left: 16px;
      }
    }

    &:hover .nav-link, &.active .nav-link {
      color: #ff6600;
    }
  }

  .dropdown {
    position: relative;

    .open > a {
      background: transparent;

      &:focus, &:hover {
        background: transparent;
      }
    }

    .dropdown-menu {
      min-width: 220px;
      margin: 0;
      padding: 0;
      border-radius: 0;
      border: 0;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

      li:not(:last-child) .dropdown-item {
        border-bottom: 1px solid #e5e5e5;
      }

      .dropdown-item {
        font-size: 15px;
        padding: 10px 16px;
        transition: 0.3s ease;

        &:hover {
          background: #fff;
          color: #ff6600;
        }
      }
    }

    &.full-width .dropdown-menu {
      left: 0 !important;
      right: 0 !important;
    }
  }

  .dropleft {
    .dropdown-menu {
      margin: 0;
      right: 0;
    }

    .dropdown-toggle::before {
      font-weight: bold;
      font-family: "FontAwesome";
      border: 0;
      font-size: 14px;
      vertical-align: 1px;
      content: "\f104";
      margin-right: 7px;
    }
  }

  .dropright {
    .dropdown-menu {
      margin: 0;
    }

    .dropdown-toggle::after {
      font-weight: bold;
      font-family: "FontAwesome";
      border: 0;
      font-size: 14px;
      vertical-align: 1px;
      content: "\f105";
      margin-left: 7px;
    }
  }

  .dropdown-submenu {
    &:hover > .dropdown-toggle, &.active > .dropdown-toggle {
      color: #ff6600;
    }
  }

  .ticket {
    background: #ff6600;
    display: block;
    padding: 34px 32px;

    img {
      margin-right: 25px;
    }

    span {
      color: #fff;
      font-size: 1.125rem;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      border-left: 1px solid #ffa366;
      padding-left: 24px;
    }
  }
}

.navbar-toggler {
  &:focus, &:hover {
    outline: none;
  }
}

.footer-main {
  background: #272735;
  padding: 90px 0;

  .block {
    img {
      margin-bottom: 60px;
    }

    ul.social-links-footer {
      margin-bottom: 0;

      .list-inline-item:not(:last-child) {
        margin-right: 20px;
      }

      li a {
        display: block;
        height: 50px;
        width: 50px;
        text-align: center;
        background: #fff;

        i {
          line-height: 50px;
        }

        &:hover {
          background: #ff6600;

          i {
            color: #fff;
          }
        }
      }
    }
  }
}

.subfooter {
  background: #2a2a37;
  padding: 18px 0;

  .copyright-text p {
    font-family: "Montserrat", sans-serif;

    a {
      color: #ff6600;
    }
  }

  .to-top {
    display: block;
    height: 45px;
    width: 45px;
    text-align: center;
    background: #ff6600;
    float: right;

    i {
      font-size: 1.5rem;
      color: #fff;
      line-height: 45px;
    }
  }
}

.cta-ticket {
  position: relative;

  .content-block {
    padding: 100px 0;

    h2 {
      color: #fff;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 15px;
    }

    p {
      color: #e6e6e6;
      margin-bottom: 45px;
    }
  }

  .image-block {
    position: absolute;
    right: 5%;
    bottom: 0;
  }
}

.cta-subscribe {
  position: relative;

  .content {
    padding: 70px 0;

    h3 {
      color: #fff;
      font-weight: 500;
    }

    p {
      color: #fff;
      font-size: 0.9375rem;
    }
  }

  .subscribe-button button {
    cursor: pointer;
  }
}

.banner {
  padding: 250px 0 150px;
  position: relative;

  .block {
    position: relative;
    z-index: 10;

    .timer .syotimer-cell {
      margin-right: 25px;
      margin-bottom: 33px;
      display: inline-block;

      .syotimer-cell__value {
        min-width: 80px;
        font-family: "Montserrat", sans-serif;
        font-size: 35px;
        line-height: 77px;
        text-align: center;
        position: relative;
        font-weight: bold;
        color: #fff;
        border: 2px solid #7c7491;
        margin-bottom: 8px;
        border-radius: 100%;
        box-shadow: 3.5px 6.062px 0px 0px rgba(0, 0, 0, 0.1);
      }

      .syotimer-cell__unit {
        text-align: center;
        font-size: 1rem;
        text-transform: lowercase;
        font-weight: normal;
        color: #fff;
        font-family: "Montserrat", sans-serif;
      }
    }

    h1 {
      text-transform: uppercase;
      font-size: 5.5rem;
      color: #ff6600;
      font-weight: 700;
      margin-bottom: 5px;
      text-shadow: 3.5px 6.062px 0px rgba(0, 0, 0, 0.1);
    }

    h2 {
      text-transform: uppercase;
      font-size: 4.625rem;
      color: #fff;
      font-weight: 700;
      text-shadow: 3.5px 6.062px 0px rgba(0, 0, 0, 0.1);
      font-style: normal;
    }

    h6 {
      color: #fff;
      font-weight: 400;
      margin-bottom: 50px;
    }
  }
}

.banner-two {
  padding: 250px 0 150px;
  position: relative;
  overflow: hidden;

  .block {
    h1 {
      color: #ff6600;
      font-size: 88px;
    }

    h2 {
      font-weight: 400;
      text-transform: uppercase;
      font-size: 74px;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 74px;
    }

    h6 {
      margin-top: 48px;
      font-weight: 400;
      margin-bottom: 60px;
    }
  }
}

.about {
  padding: 120px 0;

  .image-block {
    padding: 0px;

    &.two {
      padding: 0;
      margin-top: 30px;

      img {
        border-radius: 0;
        transform: translate(30px, -30px);
      }
    }
  }

  .content-block {
    margin-left: 15px;

    h2 {
      margin-bottom: 35px;

      &:after {
        content: "";
        width: 60px;
        height: 3px;
        background: #ff6600;
        display: block;
        margin-top: 10px;
      }
    }

    .description-one {
      margin-bottom: 20px;

      p {
        color: #222222;
        font-size: 1.0625rem;
      }
    }

    .description-two {
      margin-bottom: 30px;

      p {
        font-size: 0.9375em;
      }
    }

    .list-inline-item:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.speakers {
  position: relative;

  .speaker-item {
    background: #eae9ec;
    padding: 7px;
    margin-bottom: 30px;

    .image {
      margin-bottom: 16px;
      position: relative;
      overflow: hidden;

      .primary-overlay {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100%;
        background: #ff6600;
        opacity: 0.85;
        transition: 0.3s ease;
      }

      .socials {
        position: absolute;
        top: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        transition: 0.4s ease;

        ul {
          width: 50%;

          li {
            &.list-inline-item:not(:last-child) {
              margin-right: 7px;
              margin-bottom: 10px;
            }

            a {
              display: block;
              height: 50px;
              width: 50px;
              text-align: center;
              border: 1px solid #fff;

              i {
                font-size: 0.9375rem;
                color: #fff;
                line-height: 50px;
              }

              &:hover {
                background: #fff;

                i {
                  color: #222222;
                }
              }
            }
          }
        }
      }

      &:hover {
        .primary-overlay, .socials {
          top: 0;
        }
      }
    }

    .content {
      h5 a {
        font-size: 22px;
        color: #222222;
        font-weight: 400;
        margin-bottom: 0;

        &:hover {
          color: #ff6600;
        }
      }

      p {
        font-size: 0.875rem;
        margin-bottom: 5px;
      }
    }
  }
}

.schedule {
  .schedule-tab {
    display: flex;
    justify-content: center;
    margin-top: 14px;

    ul li.nav-item {
      margin-right: 10px;

      a {
        font-family: "Montserrat", sans-serif;
        text-transform: uppercase;
        font-size: 1.5rem;
        color: #ff6600;
        font-weight: 500;
        display: block;
        border-radius: 0;
        border: 1px solid #e5e5e5;
        padding: 20px 30px;

        span {
          margin-top: 2px;
          font-family: "Roboto", sans-serif;
          display: block;
          font-size: 1rem;
          color: #848484;
          font-weight: 400;
        }

        &.active {
          background: #ff6600;
          color: #fff;
          position: relative;

          span {
            color: #fff;
          }

          &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -20px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 20px 20px 0;
            border-color: transparent #ff6600 transparent transparent;
          }
        }
      }
    }
  }

  .schedule-contents {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 30px;

    .schedule-item {
      ul {
        margin-bottom: 0;

        li {
          list-style: none;

          div {
            display: inline-block;
          }

          .time {
            width: 20%;
          }

          .speaker, .subject {
            width: 30%;
          }
        }
      }

      li {
        &.headings {
          padding: 22px 40px;
          background: #ff6600;

          div {
            color: #fff;
            text-transform: uppercase;
            font-family: "Montserrat", sans-serif;
          }
        }

        &.schedule-details {
          border-bottom: 1px solid;
          border-left: 1px solid;
          border-right: 1px solid;
          border-color: #e5e5e5;

          .block {
            padding: 10px 40px;
            background: #fff;
            width: 100%;
            transition: 0.2s ease-in;

            div {
              color: #222222;
              font-family: "Montserrat", sans-serif;

              i {
                font-size: 1.1875em;
                color: #c7c8c9;
              }

              img {
                border-radius: 100%;
              }

              span {
                &.time {
                  margin-left: 5px;
                }

                &.name {
                  margin-left: 20px;
                  transition: 0.2s ease-in;
                }
              }
            }

            &:hover {
              box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
              transform: scale(1.01);

              span.name {
                color: #ff6600;
              }
            }
          }
        }
      }
    }
  }

  .download-button {
    padding-top: 40px;
    margin-bottom: 30px;
  }
}

.ticket-feature {
  overflow: hidden;

  .block {
    position: relative;
    padding-top: 150px;
    padding-bottom: 110px;

    .section-title {
      position: relative;

      h3 {
        font-weight: 500;
      }
    }

    a {
      position: relative;
    }

    .timer {
      position: relative;
      margin-top: 60px;
      margin-bottom: 35px;

      .syotimer-cell {
        margin-right: 25px;
        margin-bottom: 33px;
        display: inline-block;

        .syotimer-cell__value {
          min-width: 80px;
          font-family: "Montserrat", sans-serif;
          font-size: 35px;
          height: 100px;
          width: 100px;
          line-height: 100px;
          text-align: center;
          position: relative;
          font-weight: bold;
          color: #fff;
          border: 2px solid rgba(255, 255, 255, 0.35);
          background-clip: content-box;
          background: rgba(255, 255, 255, 0.26);
          margin-bottom: 8px;
          border-radius: 100%;
        }

        .syotimer-cell__unit {
          text-align: center;
          font-size: 1rem;
          text-transform: lowercase;
          font-weight: normal;
          color: #fff;
          font-family: "Montserrat", sans-serif;
        }
      }
    }
  }

  .block-2 {
    position: relative;
    padding: 150px 10%;
    height: 100%;
    background: #ff6600;

    [class*=col-] {
      &:first-child {
        border-right: 1px solid #fc9552;
        border-bottom: 1px solid #fc9552;
      }

      &:nth-child(2) {
        border-bottom: 1px solid #fc9552;
      }

      &:nth-child(3) {
        border-right: 1px solid #fc9552;
      }
    }

    .service-item {
      padding: 30px;
      text-align: center;

      i {
        color: #fff;
        font-size: 3.25rem;
        margin-bottom: 20px;
      }

      h5 {
        color: #fff;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        text-transform: uppercase;
      }
    }

    &:after {
      content: "";
      position: absolute;
      right: 100%;
      bottom: 0;
      top: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 1000px 300px;
      border-color: transparent transparent #ff6600 transparent;
    }
  }
}

.registration {
  overflow: hidden;

  .service-block {
    position: relative;
    padding: 120px 10%;

    [class*=col-] {
      &:first-child {
        border-right: 1px solid #f69351;
        border-bottom: 1px solid #f69351;
      }

      &:nth-child(2) {
        border-bottom: 1px solid #f69351;
      }

      &:nth-child(3) {
        border-right: 1px solid #f69351;
      }
    }

    .service-item {
      padding: 30px;
      text-align: center;

      i {
        color: #fff;
        font-size: 3.25rem;
        margin-bottom: 20px;
      }

      h5 {
        color: #fff;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        text-transform: uppercase;
      }
    }
  }

  .registration-block {
    position: relative;
    height: 100%;
    padding: 120px 10%;

    .block {
      position: relative;
      z-index: 9;

      .title {
        margin-bottom: 35px;

        h3 {
          color: #fff;
          font-weight: 500;
          margin-bottom: 10px;
        }

        p {
          color: #b3b3b3;
          line-height: 1;
        }
      }
    }
  }
}

.pricing {
  .pricing-item {
    border: 1px solid #e5e5e5;

    .pricing-heading {
      padding: 20px 40px 30px 40px;
      background: #fafafa;
      border-bottom: 1px solid #e5e5e5;

      .title h6 {
        text-transform: uppercase;
        font-weight: 400;
        line-height: 50px;
        border-bottom: 1px solid #e5e5e5;
      }

      .price {
        margin-top: 28px;

        h2 {
          font-size: 3.625rem;
          font-weight: 400;
          margin-bottom: 0px;

          span {
            font-size: 1.5625rem;
          }
        }
      }
    }

    .pricing-body {
      padding: 45px 40px;

      ul.feature-list li {
        list-style: none;

        p span {
          margin-right: 15px;

          &.available {
            color: #ff6600;
          }

          &.unavailable {
            color: #d2d2d2;
          }
        }

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }

    .pricing-footer {
      padding-bottom: 40px;
    }

    &.featured {
      border: none;
      box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);

      .pricing-heading {
        background: #ff6600;
        border-bottom: 1px solid #ff6600;

        .title h6 {
          color: #fff;
          border-bottom: 1px solid #f69351;
        }

        .price {
          margin-top: 28px;

          h2 {
            color: #fff;
            font-size: 3.625rem;
            margin-bottom: 0px;

            span {
              font-size: 1.5625rem;
            }
          }

          p {
            color: #fff;
          }
        }
      }
    }
  }

  &.two .pricing-item {
    border: 1px solid #e5e5e5;
    overflow: hidden;

    .pricing-heading {
      position: relative;
      margin-bottom: 10px;

      .title h6, .price {
        position: relative;
      }

      &:before {
        content: "";
        position: absolute;
        bottom: -25%;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 64px 500px 0 0;
        border-color: #fafafa transparent transparent transparent;
      }
    }

    .pricing-body {
      padding: 70px 40px 45px;

      ul.feature-list li p span.available {
        color: #ff6600;
      }
    }

    &.featured .pricing-heading:before {
      border-color: #ff6600 transparent transparent transparent;
    }
  }
}

.map {
  position: relative;

  #map {
    height: 480px;
    color: white;
  }

  .address-block {
    position: absolute;
    padding: 45px 50px 50px 50px;
    top: 20px;
    left: 10%;
    background: #ff6600;

    h4 {
      color: #fff;
      margin-bottom: 20px;
      font-size: 1.5rem;
      font-weight: 400;
    }

    li {
      color: #fff;
      margin-bottom: 20px;
    }

    ul.address-list li {
      list-style: none;
      margin-bottom: 10px;

      i {
        font-size: 1.125rem;
      }

      span {
        margin-left: 15px;
      }
    }

    a {
      margin-top: 35px;
    }
  }

  &.new {
    overflow: hidden;

    #map {
      height: 640px;
    }

    .address-block {
      left: 60%;
    }

    .register {
      width: 50%;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;

      .block {
        padding: 130px 10%;

        .title {
          position: relative;
          margin-bottom: 65px;

          h3 {
            color: #fff;
            font-weight: 400;
          }

          p {
            color: #fff;
          }
        }

        .form-control, button {
          margin-bottom: 40px;
        }
      }
    }
  }
}

.feature {
  .feature-content {
    h2, p {
      margin-bottom: 25px;
    }
  }

  .testimonial {
    p {
      font-family: "Montserrat", sans-serif;
      margin-bottom: 10px;
      font-style: italic;
      color: #242424;
    }

    ul.meta li {
      font-size: 12px;
      margin-right: 10px;

      img {
        height: 40px;
        width: 40px;
        border-radius: 100%;
      }
    }
  }
}

.app-features .app-explore {
  display: flex;
  justify-content: center !important;
  margin-bottom: 40px;
}

.banner-full {
  .image {
    display: flex;
    justify-content: center;

    img {
      height: 625px;
    }
  }

  .block {
    .logo, h1 {
      margin-bottom: 40px;
    }

    p {
      font-size: 20px;
      margin-bottom: 50px;
    }

    .app {
      margin-bottom: 20px;
    }
  }
}

.video-promo {
  padding: 150px 0;

  .content-block {
    width: 60%;
    margin: 0 auto;
    text-align: center;

    h2 {
      font-size: 30px;
      color: #fff;
    }

    p {
      margin-bottom: 30px;
    }

    a {
      i.video {
        height: 125px;
        width: 125px;
        background: #ff6600;
        display: inline-block;
        font-size: 40px;
        color: #fff;
        text-align: center;
        line-height: 125px;
        border-radius: 100%;
      }

      &:focus {
        outline: 0;
      }
    }
  }
}

.single-speaker {
  .block {
    padding-bottom: 70px;
    border-bottom: 1px solid #e5e5e5;

    .image-block {
      background: #fff;
      border: 1px solid #e5e5e5;
      padding: 6px;

      img {
        width: 100%;
      }
    }

    .content-block {
      .name h3 {
        font-weight: 400;
      }

      .profession {
        margin-bottom: 20px;

        p {
          color: #ff6600;
          font-size: 0.9375rem;
        }
      }

      .details {
        margin-bottom: 35px;

        p {
          font-size: 0.9375rem;

          &:not(:last-child) {
            margin-bottom: 35px;
          }
        }
      }

      .social-profiles {
        h5 {
          font-weight: 400;
          margin-bottom: 25px;
        }

        ul.social-list li a {
          display: block;
          height: 40px;
          width: 40px;
          background: #fff;
          border: 1px solid #e5e5e5;
          text-align: center;

          i {
            line-height: 40px;
          }

          &:hover {
            background: #ff6600;
            border: 1px solid #ff6600;
            color: #fff;
          }
        }
      }
    }
  }

  .block-2 {
    padding-top: 70px;
    padding-bottom: 10px;

    h3, p, li {
      font-size: 0.9375rem;
    }

    .personal-info {
      h5 {
        font-weight: 400;
        margin-bottom: 15px;
      }

      p {
        margin-bottom: 15px;
      }

      ul li {
        position: relative;
        list-style: none;
        margin-left: 25px;

        &:not(:last-child) {
          margin-bottom: 5px;
        }

        &:before {
          position: absolute;
          left: -25px;
          content: "\f10c";
          font-family: FontAwesome;
          color: #ff6600;
        }
      }
    }

    .skills {
      h5 {
        font-weight: 400;
        margin-bottom: 15px;
      }

      p {
        margin-bottom: 15px;
      }

      .skill-bar {
        margin-bottom: 20px;

        p {
          margin-bottom: 5px;
        }

        .progress {
          border-radius: 0;
          overflow: visible;

          .progress-bar {
            background-color: #ff6600;

            span {
              font-family: "Montserrat", sans-serif;
              float: right;
              color: white;
            }
          }
        }
      }
    }
  }
}

.speakers-full-width {
  overflow: hidden;

  .speaker-slider {
    .speaker-image {
      position: relative;
      cursor: pointer;
      overflow: hidden;

      img {
        width: 100%;
      }

      .primary-overlay {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100%;
        background: #ff6600;
        opacity: 0.85;
        transition: 0.3s ease;

        h5 {
          color: #fff;
          font-family: "Montserrat", sans-serif;
          font-weight: 400;
          padding-top: 25%;
          margin-bottom: 10px;
          transition: 0.7s ease;
          transform: translate(0, 30px);
          opacity: 0;
        }

        p {
          color: #fff;
          font-family: "Montserrat", sans-serif;
          font-weight: 400;
          transition: 0.7s ease;
          transform: translate(0, 30px);
          opacity: 0;
          margin-bottom: 10px;
        }

        i {
          color: #fff;
        }

        ul {
          transition: 0.7s ease;
          transform: translate(0, 30px);
          opacity: 0;

          .list-inline-item:not(:last-child) {
            margin-right: 10px;
          }
        }
      }

      &:hover .primary-overlay {
        top: 0;

        h5, p, ul {
          transform: translate(0, 0);
          opacity: 1;
        }
      }
    }

    .slick-prev {
      left: 60px;
      background: #fff;
      width: 70px;
      height: 70px;
      border-radius: 100%;
      z-index: 100;

      &:before {
        content: "\f104";
        color: #ff6600;
        font-family: FontAwesome;
      }

      &:hover:before {
        color: #ff6600;
      }
    }

    .slick-next {
      right: 60px;
      background: #fff;
      width: 70px;
      height: 70px;
      border-radius: 100%;
      z-index: 100;

      &:before {
        content: "\f105";
        color: #ff6600;
        font-family: FontAwesome;
      }

      &:hover:before {
        color: #ff6600;
      }
    }
  }
}

.controls {
  text-align: center;
  margin-bottom: 40px;

  .btn {
    background: transparent;
    border: none;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    margin-right: 25px;

    &:focus {
      outline: 0;
      box-shadow: none;
    }

    &:hover {
      color: #ff6600;
    }

    &.focus {
      outline: 0;
      box-shadow: none;
    }
  }
}

.mixitup-control-active {
  color: #ff6600;
}

.gallery-wrapper {
  .gallery-item {
    padding: 15px;

    .image-block {
      padding: 6px;
      border: 1px solid #e5e5e5;

      .image {
        position: relative;
        overflow: hidden;

        img {
          width: 100%;
        }

        .primary-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #ff6600;
          opacity: 0;
          transform: scale(0.8);
          transition: 0.3s ease;

          a {
            display: block;
            height: 55px;
            width: 55px;
            text-align: center;
            border: 1px solid #fff;
            border-radius: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            outline: none;

            i {
              color: #fff;
              line-height: 55px;
              font-size: 1.25rem;
            }
          }
        }

        &:hover .primary-overlay {
          opacity: 0.85;
          transform: scale(1);
        }
      }
    }
  }

  .mix {
    display: inline-block;
    vertical-align: top;
    background: #fff;
    position: relative;
    width: calc(50% - 0.5rem);
  }

  .gap {
    display: inline-block;
    vertical-align: top;
  }

  &.less-gutter .gallery-item {
    padding: 5px;

    .image-block {
      padding: 0;
      border: none;
    }
  }
}

.gallery-full {
  overflow: hidden;

  .image {
    position: relative;
    overflow: hidden;
    margin-bottom: -1px;

    img {
      width: 100%;
    }

    .primary-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #ff6600;
      opacity: 0;
      transform: scale(0.8);
      transition: 0.3s ease;

      a {
        display: block;
        height: 55px;
        width: 55px;
        text-align: center;
        border: 1px solid #fff;
        border-radius: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        outline: none;

        i {
          color: #fff;
          line-height: 55px;
          font-size: 1.25rem;
        }
      }
    }

    &:hover .primary-overlay {
      opacity: 0.85;
      transform: scale(1);
    }
  }
}

.news {
  .blog-post {
    transition: 0.2s ease;
    margin-bottom: 30px;

    .post-content {
      position: relative;
      padding: 30px 30px 25px 30px;
      border-width: 0 1px 1px 1px;
      border-style: solid;
      border-color: #e5e5e5;

      .date {
        position: absolute;
        top: -32px;
        left: 30px;
        width: 65px;
        height: 65px;
        text-align: center;
        background: #ff6600;
        display: flex;
        align-items: center;
        justify-content: center;

        h4 {
          color: #fff;

          span {
            display: block;
            font-size: 1rem;
            font-weight: 400;
          }
        }
      }

      .post-title {
        padding-bottom: 5px;
        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 15px;

        h2 {
          margin-top: 10px;

          a {
            font-weight: 400;
            color: #222222;

            &:hover {
              color: #ff6600;
            }
          }
        }
      }

      .post-meta ul {
        margin-bottom: 0;

        li {
          i {
            margin-right: 7px;
          }

          a {
            display: inline-block;
            padding-right: 25px;
            font-size: 0.875rem;
          }
        }
      }
    }

    &:hover {
      box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
    }

    &.single {
      margin-bottom: 100px;

      .post-title {
        padding-top: 35px;
        padding-bottom: 0;
        border: none;
        margin-bottom: 10px;

        h3 {
          font-weight: 400;
        }
      }

      .post-meta {
        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 10px;
      }

      .post-details {
        p {
          padding: 13px 0;
          font-size: 15px;
        }

        .quotes {
          background: #f5f5f8;
          padding: 30px;
          border-left: 3px solid #ff6600;
          margin: 15px 0;

          blockquote {
            font-size: 15px;
            font-style: italic;
          }
        }

        .share-block {
          display: flex;
          padding-top: 20px;
          padding-bottom: 25px;

          p {
            padding: 0;
          }

          .tag {
            flex-basis: 50%;
            align-self: center;

            p {
              display: inline;
              font-family: "Montserrat", sans-serif;
              color: #222222;
            }

            ul {
              display: inline;
              font-family: "Montserrat", sans-serif;

              li a {
                color: #848484;
                font-size: 15px;

                &:hover {
                  color: #ff6600;
                }
              }
            }
          }

          .share {
            flex-basis: 50%;
            text-align: right;

            p {
              display: inline;
              font-family: "Montserrat", sans-serif;
              margin-right: 18px;
            }

            ul {
              display: inline;
              font-family: "Montserrat", sans-serif;

              &.social-links-share {
                margin-bottom: 0;

                .list-inline-item:not(:last-child) {
                  margin-right: 7px;
                }

                li a {
                  display: block;
                  height: 38px;
                  width: 38px;
                  text-align: center;
                  background: #fff;
                  border: 1px solid #e5e5e5;

                  i {
                    line-height: 38px;
                  }

                  &:hover {
                    background: #ff6600;
                    border-color: #ff6600;

                    i {
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &:hover {
        box-shadow: none;
      }
    }
  }

  .comments {
    padding-bottom: 80px;

    h5 {
      font-weight: 400;
      margin-bottom: 10px;
    }

    .comment {
      padding-top: 14px;

      img {
        margin-right: 30px;
      }

      .media-body {
        h6 {
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 10px;
        }

        p {
          font-size: 15px;
          margin-bottom: 14px;
        }

        ul {
          font-size: 15px;
          margin-bottom: 10px;

          li {
            span {
              margin-right: 7px;
            }

            a {
              color: #ff6600;
            }
          }
        }
      }
    }
  }

  .comment-form {
    h5 {
      font-weight: 400;
      margin-bottom: 30px;
    }

    .form-control, button {
      margin-bottom: 30px;
    }
  }
}

.sidebar {
  padding: 0 20px;
}

.widget {
  margin-bottom: 45px;

  .widget-header {
    font-weight: 400;
    margin-bottom: 20px;
  }

  &.search .input-group {
    .form-control {
      border-right: none;
    }

    .input-group-addon {
      background: #ff6600;
      color: #fff;
      border: none;
      border-radius: 0;
      line-height: 45px;
      padding: 0 10px;

      i {
        font-size: 15px;
        padding-right: 5px;
        padding-left: 5px;
      }
    }
  }

  &.category ul.category-list li {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e5e5e5;
    list-style: none;

    a {
      font-size: 15px;
      letter-spacing: 0;
      display: block;

      &:hover {
        color: #ff6600;
      }
    }

    &:last-child {
      margin-bottom: 5px;
    }
  }

  &.tags ul li a {
    display: block;
    padding: 10px 25px;
    border: 1px solid #e5e5e5;
    margin-bottom: 15px;
    font-size: 15px;

    &:hover {
      background: #ff6600;
      color: #fff;
      border-color: #ff6600;
    }
  }

  &.latest-post .media {
    padding: 15px 0;

    &:not(:last-child) {
      border-bottom: 1px solid #e5e5e5;
    }

    img {
      margin-right: 15px;
    }

    .media-body {
      h6 a {
        font-size: 15px;
        color: #222222;
        line-height: 1;
        font-weight: 400;

        &:hover {
          color: #ff6600;
        }
      }

      p span {
        margin-right: 10px;
      }
    }
  }
}

.error .block {
  margin-top: 60px;
  margin-bottom: 60px;

  img {
    margin-bottom: 45px;
  }

  h3 {
    font-weight: 400;
    color: #ff6600;
    margin-bottom: 40px;

    span {
      font-style: italic;
      color: #222222;
    }
  }
}

.faq .accordion-block {
  #accordion .card, #accordionTwo .card {
    border-radius: 0;
    margin-bottom: 20px;

    .card-header {
      background: #fff;
      padding: 0;

      h5 a {
        font-weight: 400;
        color: #222222;

        span {
          padding: 20px;
          border-right: 1px solid #e5e5e5;
          margin-right: 20px;
        }
      }
    }

    .card-body {
      color: #848484;
      padding: 15px 20px;
    }

    .collapse.show + .card-header h5 a {
      color: #ff6600;
    }
  }
}

.contact-form {
  .form-control {
    margin-bottom: 30px;
  }

  button {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .text-overlay:after {
    font-size: 100px;
  }

  .overlay-white-slant:before {
    content: none;
  }

  .bg-banner-two {
    background-position: center center;
  }

  .left img {
    margin-left: 0;
    margin-bottom: 30px;
  }

  .right img {
    margin-left: 0;
  }

  .main-nav {
    position: relative;

    .navbar-brand {
      border-right: none;
    }
  }

  .page-title {
    padding: 65px 0;
  }

  .footer-main {
    padding: 50px 0;
  }

  .subfooter {
    .copyright-text {
      text-align: center;
    }

    .to-top {
      float: none;
      margin: auto;
      margin-top: 30px;
    }
  }

  .cta-ticket .image-block {
    display: none;
  }

  .cta-subscribe .subscribe-button {
    margin-bottom: 30px;
  }

  .banner .block {
    h1 {
      font-size: 4rem;
    }

    h2 {
      font-size: 3.2rem;
    }
  }

  .about {
    .image-block {
      margin-bottom: 10px;
      text-align: center;

      &.two img {
        transform: translate(0, -30px);
      }
    }

    .content-block {
      text-align: center;

      h2:after {
        margin: auto;
      }
    }
  }

  .schedule {
    .schedule-tab ul li.nav-item {
      width: 100%;
      margin-bottom: 10px;

      a.active:after {
        content: none;
      }
    }

    .schedule-contents .schedule-item ul li {
      .time {
        width: 37%;
      }

      .speaker {
        width: 60%;
      }

      .venue {
        display: none;
      }
    }

    &.two {
      .schedule-tab ul li.nav-item {
        width: 100%;
        margin-bottom: 10px;
        margin-right: 0;
      }

      .schedule-contents .schedule-item ul li {
        .time {
          width: 37%;
        }

        .speaker {
          width: 60%;
        }

        .venue {
          display: none;
        }
      }
    }
  }

  .map.new {
    .address-block {
      left: 5%;
    }

    .register {
      width: 100%;
      position: relative;
    }
  }

  .feature {
    .feature-content {
      h2, p {
        text-align: center;
      }
    }

    .testimonial {
      text-align: center;
    }
  }

  .banner-full {
    .image {
      margin-bottom: 30px;
    }

    .block {
      text-align: center;
    }
  }

  .testimonial .testimonial-slider .item .block .image {
    flex-grow: 1;
  }

  .single-speaker {
    .block .image-block, .block-2 .personal-info {
      margin-bottom: 30px;
    }
  }

  .speakers-full-width .speaker-slider {
    .slick-prev {
      left: 20px;
    }

    .slick-next {
      right: 20px;
    }
  }

  .controls .btn {
    margin-right: 10px;
  }

  .news .blog-post .post-thumb img {
    width: 100%;
  }

  .widget.search {
    margin-top: 30px;
  }
}

@media (max-width: 575px) {
  .text-overlay:after {
    font-size: 70px;
  }

  .footer-main .block ul.social-links-footer .list-inline-item:not(:last-child) {
    margin-right: 5px;
  }

  .banner .block .timer .syotimer-cell {
    margin-right: 10px;
    margin-bottom: 20px;

    .syotimer-cell__value {
      font-size: 30px;
    }
  }

  .banner-two .block {
    h1 {
      font-size: 40px;
    }

    h2, h3 {
      font-size: 35px;
    }

    h6 {
      margin-top: 30px;
    }
  }

  .about .content-block {
    ul.list-inline .list-inline-item {
      display: block;
    }

    .list-inline-item:not(:last-child) {
      margin-right: 0;
    }
  }

  .speakers .speaker-item .image img {
    width: 100% !important;
  }

  .schedule {
    .schedule-contents .schedule-item {
      ul li {
        .time {
          width: 48%;
        }

        .speaker {
          width: 50%;

          img {
            display: none;
          }
        }
      }

      li.schedule-details .block div span.name {
        margin-left: 0;
      }
    }

    &.two .schedule-contents .schedule-item {
      ul li {
        .time {
          width: 48%;
        }

        .speaker {
          width: 50%;
        }
      }

      li.schedule-details .block div span.name {
        margin-left: 0;
      }
    }
  }

  .ticket-feature {
    .block .timer .syotimer-cell {
      margin-right: 10px;
      margin-bottom: 20px;

      .syotimer-cell__value {
        font-size: 30px;
        height: 80px;
        width: 80px;
        line-height: 80px;
      }
    }

    .block-2 {
      padding: 50px 20px;

      .service-item h5 {
        font-size: 1rem;
      }
    }
  }

  .registration .service-block .service-item h5 {
    font-size: 12px;
  }

  .map .address-block {
    display: none;
  }

  .app-features .app-feature {
    margin-bottom: 30px;
  }
}

@media (max-width: 1200px) {
  .main-nav {
    .navbar-brand {
      padding: 20px 30px;
    }

    .navbar-nav .nav-item .nav-link {
      padding-left: 4px;
      padding-right: 10px;

      span {
        margin-left: 14px;
      }
    }

    .ticket {
      padding: 23px 26px;

      img {
        display: none;
      }

      span {
        padding-left: 0;
        font-size: 1rem;
        border-left: none;
      }
    }
  }

  .page-title {
    padding: 140px 0 65px;
  }

  .speakers .speaker-item .image .socials ul {
    width: 100%;
    text-align: center;
    padding: 0 20px;

    li.list-inline-item {
      &:not(:last-child), &:last-child {
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }

  .schedule {
    .schedule-contents .schedule-item ul li .subject, &.two .schedule-contents .schedule-item ul li .subject {
      display: none;
    }
  }

  .ticket-feature .block-2:after {
    content: none;
  }

  .news-hr .news-post-hr {
    flex-direction: column;

    .post-thumb {
      flex-basis: 100%;
    }

    .post-contents {
      border-left: 1px solid #e5e5e5;
      flex-basis: 100%;

      &:after {
        content: none;
      }
    }
  }
}

@media (max-width: 1352px) {
  .main-nav {
    .navbar-nav .nav-item .nav-link {
      font-size: 0.86rem;
      padding-right: 10px;
    }

    .ticket {
      padding: 34px 26px;
    }
  }
}

@media (max-width: 991px) {
  .main-nav {
    .navbar-nav .nav-item .nav-link {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      span {
        display: none;
      }
    }

    .dropdown .dropdown-menu {
      transform: none;
      left: auto;
      position: relative;
      text-align: center;
      opacity: 1;
      visibility: visible;
      background: #f3f3f3;

      li:not(:last-child) .dropdown-item {
        border: 0;
      }
    }

    .ticket {
      text-align: center;
    }
  }

  .about .content-block .list-inline-item:not(:last-child) {
    margin-bottom: 10px;
  }

  .schedule {
    .schedule-contents .schedule-item ul li .speaker {
      width: 50%;
    }

    &.two {
      .schedule-tab ul li.nav-item {
        margin-right: 10px;

        a.active:after {
          content: none;
        }
      }

      .schedule-contents .schedule-item ul li .speaker {
        width: 50%;
      }
    }
  }

  .pricing {
    .pricing-item {
      margin-bottom: 30px;
    }

    &.two .pricing-item {
      margin-bottom: 30px;

      .pricing-heading:before {
        content: none;
      }
    }
  }

  .sponsors .block .list-inline-item {
    &:not(:last-child), &:last-child {
      margin-right: 7px;
      margin-left: 7px;
    }
  }

  .map.new .address-block {
    left: 52%;
  }

  .news .blog-post.single .post-details .share-block {
    flex-wrap: wrap;

    .tag {
      flex-basis: 100%;
      text-align: center;
      margin-bottom: 30px;
      flex-grow: 1;
    }

    .share {
      flex-basis: 100%;
      flex-grow: 1;
      text-align: center;
    }
  }

  .news-hr .news-post-hr {
    margin-bottom: 30px;
  }
}

@media (max-width: 1024px) {
  .main-nav .navbar-nav .nav-item .nav-link span {
    margin-left: 10px;
  }

  .banner {
    padding: 100px 0;
  }

  .speakers-full-width .speaker-slider {
    .slick-prev, .slick-next {
      height: 35px;
      width: 35px;
    }
  }
}

@media (min-width: 992px) {
  .main-nav .dropdown {
    .dropdown-menu {
      transition: 0.3s all;
      position: absolute;
      display: block;
      opacity: 0;
      visibility: hidden;
      transform: translateY(30px);
    }

    &:hover > .dropdown-menu {
      border-top: 3px solid #ff6600;
      opacity: 1;
      display: block;
      visibility: visible;
      color: #777;
      transform: translateY(0);
    }

    .dropleft .dropdown-menu {
      right: 100%;
    }
  }
}

@media (max-width: 400px) {
  .banner .block {
    .timer .syotimer-cell .syotimer-cell__value {
      min-width: 65px;
      line-height: 61px;
      font-size: 25px;
    }

    h1 {
      font-size: 3rem;
    }

    h2 {
      font-size: 2.3rem;
    }
  }

  .news .blog-post.single .post-details .share-block .share {
    p {
      display: block;
      margin-bottom: 10px;
    }

    ul.social-links-share {
      text-align: left;

      .list-inline-item:not(:last-child) {
        margin-right: 5px;
        margin-bottom: 5px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .gallery-wrapper .mix {
    width: calc(33.3333333333% - 0.6666666667rem);
  }
}

@media screen and (min-width: 1200px) {
  .gallery-wrapper.less-gutter {
    .mix, .gap {
      width: calc(25% - 0.75rem);
    }
  }
}

.cotizador {
  .card-header {
    padding: 15px 40px;
    border-radius: 0;
    background: #ff6600;
    color: #fff;
    outline: none;
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.2rem;
  }
}

.login {
  .card {
    border: 0;
  }

  .card-group {
    display: flex;
    flex-flow: row wrap;

    > .card {
      flex: 1 0 0%;
      margin-bottom: 0;
    }
  }

  .card-body {
    flex: none;

    h3 {
      font-size: 1.2rem;
      font-weight: 700;
    }

    .input-group-text {
      border-radius: 0px;
      background: #ff6600;
      color: #fff;
      outline: none;
      font-size: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      border: none;
    }

    .btn-link {
      font-size: 0.8rem;
      color: #272735;
    }
  }
}

@media (max-width: 768px) {
  .login .card-group {
    display: block;

    > .card {
      display: block;
    }
  }
}

.contact-block {
  padding: 25px 20px 20px 25px;
  background: #ff6600;

  h4 {
    color: #fff;
    margin-bottom: 20px;
    font-size: 1.2rem;
    font-weight: 700;
  }

  li {
    color: #fff;
    margin-bottom: 1rem;
  }

  ul.address-list li {
    font-size: 0.9rem;
    list-style: none;
    margin-bottom: 2px;

    i {
      font-size: 1rem;
    }

    span {
      margin-left: 10px;
    }
  }

  a {
    margin-top: 35px;
  }
}

.floatWhatsapp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;

  &:hover {
    text-decoration: none;
    color: #25d366;
    background-color: #fff;
  }
}

.my-float {
  margin-top: 16px;
}
